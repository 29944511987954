import { Link } from "gatsby"

import Layout from "src/layout"
import Image from "src/components/Image"

const HomePage: GatsbyPage = () => {
  return (
    <Layout title="Home">
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link>
    </Layout>
  )
}

export default HomePage
